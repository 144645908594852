a {
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

#lastButton {
  margin-left: 15px;
  margin-right: 25px;
}

#songTitle {
  display: flex;
  align-items: baseline; /* Vertical align the elements to the center */
  padding-bottom: 10px;
}

nav {
  margin-bottom: 10px;
}

#home {
  word-wrap: break-word;
  width: 800px;
}

#message {
  margin-top: -12px;
  margin-bottom: 8px;
}

#main {
  padding: 25px;
  margin-top: 50px;
}

#card {
  margin-bottom: 25px;
}

#pic {
  text-align: right;
}

#queue {
  margin-left: 15px;
}

#homeMain {
  padding: 25px;
}

#loginfo {
  margin: 0px;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p.pclass {
  line-height: 0.8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
